import { createApp } from "vue";

import App from "./App.vue";
import router from "./router";
import VueApexCharts from "vue3-apexcharts";
import vClickOutside from "click-outside-vue3";
import { registerScrollSpy } from "vue3-scroll-spy";
import { useAuthStore } from "@/state/pinia";
import { vMaska } from "maska";
import Toast from "vue-toastification";
// Import the CSS or use your own!
import "vue-toastification/dist/index.css";
import i18n from "./i18n";

import BootstrapVueNext from "bootstrap-vue-next";

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue-next/dist/bootstrap-vue-next.css";
import "@/assets/scss/app.scss";
import "@vueform/multiselect/themes/default.css";

// PINIA
import pinia from "@/state/pinia";

import "sweetalert2/dist/sweetalert2.min.css";
import "@vueform/slider/themes/default.css";
import axios from "axios";

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (!error.response) {
      alert("Verifique sua conexão com a internet!");
    }
    if (error.response.status === 401) {
      const auth = useAuthStore();
      auth.logOut();
      console.log("pinia", app.$store);
      app.$router.push({ name: "login" });
    }
    return Promise.reject(error);
  }
);

var app = createApp(App)
  .use(pinia)
  .use(router)
  .use(Toast)
  .use(require("vue-chartist"))
  .use(BootstrapVueNext)
  .use(VueApexCharts)
  .use(vClickOutside)
  .use(i18n)
  .use(registerScrollSpy)
  .directive("maska", vMaska)
  .mount("#app");
